/**
 * ==========================  场景：列表&搜索  ==========================
 */

import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'

/**
 * 页面打开成功次数累加
 * @param {Object} vm - vue实例
 * @param {Object} newData - 页面数据
 * */
const monitorPageOpen = (vm, { newData }) => {
  const { pageName, fromPageType, pageFrom, entranceType, src_module } = newData.cat_info || {}
  const originalUrl = vm.$route.fullPath
  const isSpaPage = vm.PageState?.isSpaPage
  const status = newData?.goods?.length > 0 ? '1' : '0'
  const marketingStatus = isMarketingPage() ? '1' : '0'

  metricPageSuccess({ page: pageName, status, marketingStatus }, 'page_success_total', {
    ...(newData.cat_info || {}),
    originalUrl,
    isSpaPage,
  })

  const pageSource =
    fromPageType === 'category'
      ? pageFrom
      : entranceType || fromPageType || pageFrom || src_module || 'otherss'
  metricPageSuccess(
    { page: pageName, status, pageSource, marketingStatus },
    'page_success_total_with_source',
  )
}

function isMarketingPage() {
  if (typeof window === 'undefined') return false
  // 营销下面有用增+广告+crm+推客+红人等等多个业务域
  // ad_type=DPA/SDPA/SEARCH是广告 KOL是红人 KOC是推客
  const searchParams = window.location.search
  const map = {
    ad_type: ['DPA', 'SDPA', 'SEARCH'],
    scene: ['onelink'],
    pf: true,
    url_from: true,
    aff_id: true,
    onelink: true,
  }
  return Object.keys(map).some((key) => {
    if (map[key] === true) {
      return searchParams.includes(`${key}=`)
    }
    return map[key].some((value) => searchParams.includes(`${key}=${value}`))
  })
}

export { monitorPageOpen, isMarketingPage }
