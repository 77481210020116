/**
 * 互斥锁
 * 用于解决并发问题
 * 保证同一时刻只有一个任务在执行
 */

class Mutex {
  constructor() {
    this.queue = Promise.resolve()
  }

  async runExclusive(task) {
    let release
    const nextTask = new Promise(resolve => (release = resolve))

    // 把新任务加入队列
    const currentTask = this.queue.then(async () => {
      try {
        return await task()
      } finally {
        release() // 释放锁
      }
    })

    this.queue = nextTask // 让下一个任务等待

    return currentTask
  }
}

export default Mutex

/**
 * 使用示例

const mutex = new Mutex();

async function task(name) {
  await mutex.runExclusive(async () => {
    console.log(`Start: ${name}`);
    await new Promise((resolve) => setTimeout(resolve, 2000)); // 模拟异步任务
    console.log(`End: ${name}`);
  });
}

task("A");
task("B");
task("C");

Start: A
(2秒后)
End: A
Start: B
(2秒后)
End: B
Start: C
(2秒后)
End: C

👉 任务是按顺序执行的，互不干扰，避免了并发！ 🎯
 */
