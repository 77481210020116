<template>
  <div class="search-banner-card" style="background-color: #fff;">
    <component
      :is="cardType"
      v-if="cardType"
      :ref="cardTypeRef"
      :searchStoreCardArr="searchStoreCard"
      :searchTrendCard="searchTrendCard"
      :searchCCCCard="searchCCCCard"
      v-bind="useMapAttrs($attrs)"
    />
  </div>
</template>

<script setup>
import { /*defineAsyncComponent,*/ computed, defineExpose, ref } from 'vue'
import TrendCard from './trendCard'
import StoreCard from './storeCard'
import CccCard from './cccCard'
import { useMapAttrs } from 'public/src/pages/common/vuex-composition.js'

// TODO 按需加载 会在spa跳转的时候导致列表先加载再加载卡片 待优化
// import loadingComponent from './loading.vue'
// const TrendCard = defineAsyncComponent({
//   loader: () => import( /* webpackPreload: true */ './trendCard'),
//   loadingComponent,
// })
// const StoreCard = defineAsyncComponent({
//   loader: () => import( /* webpackPreload: true */ './storeCard'),
//   loadingComponent,
// })
// const CccCard = defineAsyncComponent({
//   loader: () => import( /* webpackPreload: true */ './cccCard'),
//   loadingComponent,
// })


const storeCardRef = ref(null)
const trendCardRef = ref(null)
const cccCardRef = ref(null)

const props = defineProps({
  searchStoreCard: {
    type: Array,
    default: () => [],
  },
  searchTrendCard: {
    type: Object,
    default: () => ({}),
  },
  searchCCCCard: {
    type: Object,
    default: () => ({}),
  },
})
const cardType = computed(() => {
  if (props.searchStoreCard?.length) {
    return StoreCard
  } else if (props.searchTrendCard) {
    return TrendCard
  } else if (props.searchCCCCard) {
    return CccCard
  } else {
    return null
  }
})

const cardTypeRef = computed(() => {
  if (props.searchStoreCard?.length) {
    return storeCardRef
  } else if (props.searchTrendCard) {
    return trendCardRef
  } else if (props.searchCCCCard) {
    return cccCardRef
  } else {
    return ref(null)
  }
})
defineExpose({
  resetFalconlazy: () => {
    if (cccCardRef?.value) {
      cccCardRef.value.resetFalconlazy()
    }
  },
})
</script>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SearchCard',
})
</script>

<style lang="less"></style>
