<!-- eslint-disable @shein-aidc/cccx/notUseImgTag -->
<template>
  <div 
    v-if="storeCardNum"
    :class="['banner-wrap', {'fashion-card': !!fashionBg}, {'multiple-card': storeCardNum > 1}, {'two-card': storeCardNum === 2}]"
  >
    <div 
      v-for="searchStoreCard in searchStoreCardArr" 
      :key="searchStoreCard.store_code"
      v-expose="{
        id: '2-27-10',
        code: exposeCode,
        delayReady: 1500,
        data: {
          is_multiple_card: storeCardNum > 1,
          store_code: searchStoreCard.store_code,
          store_banner: searchStoreCard.storeBanners?.[0]?.icon ? 1 : 0
        }
      }"
      :class="['search-shop-banner', multipleFashionBg(searchStoreCard) && 'search-shop-banner_special']"
      @click="handleStoreEntryClick(searchStoreCard, $event)"
    >
      <header class="search-shop-banner__header header">
        <div class="header__left">
          <BaseImg
            class="header__logo"
            :first-screen="true"
            :is-support-webp="true"
            :img-design-width="40 * 2"
            :img-src="searchStoreCard.logo"
          />
          <div class="header__content">
            <div class="header__title">
              {{ searchStoreCard.title }}
            </div>
            <div class="header__labels-container">
              <div
                v-for="(item, index) in searchStoreCard.storeLabels"
                :key="index"
                class="header__labels"
              >
                <!-- 时尚标签 -->
                <div 
                  v-if="item.labelType == '2'"
                  class="header__label-image"
                >
                  <img
                    v-if="item?.iconVo?.imgUrl"
                    :src="item?.iconVo?.imgUrl"
                  />
                  <div 
                    v-else
                    class="header__label-fallback"
                  >
                    <span>{{ item?.labelName || '' }}</span>
                  </div>
                </div>
                <!-- 品质标签 -->
                <div 
                  v-if="item.labelType == '4'"
                  class="header__label-image"
                >
                  <img
                    :src="item?.iconVo?.imgUrl || ''"
                  />
                </div>
                <!-- 品牌店铺 -->
                <div
                  v-if="item.labelType == '1'"
                  class="header__store_brand header__label"
                >
                  <BaseImg
                    class="header__label_icon"
                    :first-screen="true"
                    :is-support-webp="true"
                    :img-design-width="12"
                    style="transform: translateY(0.4px);"
                    img-src="https://img.ltwebstatic.com/images3_ccc/2024/12/02/de/17331334597bc147648042508530bb02071e0c7e93.png"
                  />
                  <div 
                    class="header__label_txt" 
                    style="transform: translateY(0.5px);"
                  >
                    {{ language.SHEIN_KEY_PWA_24319 }}
                  </div>
                </div>
              
                <!-- 优质店铺 -->
                <div
                  v-if="item.labelType == '3'"
                  class="header__store_best header__label"
                >
                  <BaseImg
                    class="header__label_icon"
                    :first-screen="true"
                    :is-support-webp="true"
                    :img-design-width="12"
                    :img-src="`${locals.PUBLIC_CDN}/pwa_dist/images/store/store_preferred_seller-bb758e2772.png`"
                  />
                  <div class="header__label_txt header__color_best ">
                    {{ language.SHEIN_KEY_PWA_20758 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div 
          v-if="storeCardNum === 1"
          class="header__right" 
        >
          <div>{{ language.SHEIN_KEY_PWA_20627 }}</div>
          <i class="suiiconfont sui_icon_more_right_12px"></i>
        </div>
      </header>
      <section 
        v-if="showShopCenterInfo(searchStoreCard)" 
        class="search-shop-banner__content store-data"
      >
        <div
          v-if="searchStoreCard.storeRating && searchStoreCard.storeRating !== '0'"
          class="store-data__line"
        >
          <div class="store-data__key">
            {{ language.SHEIN_KEY_PWA_20750 }}
          </div>
          <div class="store-data__mark">
            :
          </div>
          <div class="store-data__value">
            {{ formatShopScore(searchStoreCard) }}
          </div>
        </div>
        <div
          v-if="searchStoreCard.storeWishCount && searchStoreCard.storeWishCount !== '0'"
          class="store-data__line"
        >
          <div class="store-data__key">
            {{ language.SHEIN_KEY_PWA_24069 }}
          </div>
          <div class="store-data__mark">
            :
          </div>
          <div class="store-data__value">
            {{ searchStoreCard.storeWishCount }}
          </div>
        </div>
        <div
          v-if="storeTags(searchStoreCard)?.length && storeCardNum === 1"
          class="store-data__hot-labels"
        >
          <div 
            v-if="(searchStoreCard.storeRating && searchStoreCard.storeRating !== '0') || (searchStoreCard.storeWishCount && searchStoreCard.storeWishCount !== '0')" 
            class="store-data__divider-line"
          ></div>
          <StoreLabelSwiper :labelList="storeTags(searchStoreCard)" />
        </div>
      </section>
      <section 
        v-if="searchStoreCard.storeBanners?.[0]?.icon"
        class="search-shop-banner__banner"
      >
        <section
          class="search-shop-banner__banner-img"
          :style="'background-image: url('+ searchStoreCard.storeBanners?.[0]?.icon +');'"
        >
        </section>
      </section>
      <section
        v-if="products(searchStoreCard)?.length"
        class="goods-list"
      >
        <div 
          v-for="(item, index) in products(searchStoreCard)"
          :key="index"
        >
          <div 
            class="goods-list__item"
            @click.stop="handleStoreDetailClick(item, searchStoreCard)"
          >
            <img
              class="goods-list__img"
              :src="item.goods_img"
            />
            
            <div class="goods-list__price">
              <ProductCardPriceSimple 
                :goods-info="item"
                :language="language"
                :isPaid="!!sheinClubInfo?.isPaid" 
                :config="{
                  hidePriceBottomLabel: true,
                  noCamelCasePrice: true,
                  priceColor: searchStoreCard.suggestedSaleType
                }"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive'
import { defineComponent, defineAsyncComponent } from 'vue'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import { parseQueryString, stringifyQueryString } from '@shein/common-function'
import ProductCardPriceSimple from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/ProductCardPriceSimple.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
// import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'

daEventCenter.addSubscriber({ modulecode: '2-27' })
export default defineComponent({
  name: 'SearchShopBanner',
  directives: {
    expose,
  },
  components: {
    BaseImg,
    ProductCardPriceSimple,
    StoreLabelSwiper: defineAsyncComponent(() => import('public/src/pages/components/ccc/components/shop-banner/StoreLabelSwiper.vue'))
  },
  props: {
    listAbtResult: {
      type: Object,
      default() {
        return {}
      }
    },
    searchStoreCardArr: {
      type: Array,
      default() {
        return []
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    sheinClubInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    locals: {
      type: Object,
      default() {
        return {}
      }
    },
    exposeCode: {
      type: String,
      default: ''
    },
    // 03-25需求删除掉了，换在组件内进行判断
    fashionBg: {
      type: String,
      default: ''
    },
    constantData: {
      type: Object,
      default() {
        return {}
      }
    },
    isPaid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // 店铺卡片数量（2个和 多个样式不太一样）
    storeCardNum() {
      return this.searchStoreCardArr?.length || 0
    },
  },
  methods: {
    multipleFashionBg(searchStoreCard) {
      if(this.storeCardNum === 1) {
        return false
      }
      // 参见store 里topBackgroundParam的逻辑
      const isTrendStore = (searchStoreCard.storeLabels ?? []).find(item => item.labelType == '2')
      // const fashionStyle = this.listAbtResult?.newsearchtrendstore?.p?.newsearchtrendstore === 'new' && isTrendStore
      // if (!fashionStyle) {
      //   return false
      // }
      if(!isTrendStore) {
        return false
      }

      return true
    },
    products(searchStoreCard) {
      if(this.storeCardNum === 1) {
        return searchStoreCard.shopRecProducts
      }
      // 数量处理逻辑是在bff中间层,相关的转换在public/src/pages/product_list_v2/js/bffAdapter.js中处理
      return searchStoreCard.shopRecProducts?.slice(0, 2) ?? []
    },
    showShopCenterInfo (searchStoreCard) {
      const { storeRating, storeWishCount } = searchStoreCard
      return !!parseFloat(storeRating) || !!parseInt(storeWishCount) || !!this.storeTags(searchStoreCard).length
    },
    storeTags (searchStoreCard) {
      return (searchStoreCard?.storeServiceLabels ?? []).map(item => ({
        description: 'sold',
        iconLink: item.icon,
        title: item.labelLang
      }))
    },
    formatShopScore(searchStoreCard) {
      return (+searchStoreCard.storeRating).toFixed(2)
    },
    // 跳转到店铺主页
    handleStoreEntryClick(searchStoreCard, { target }) {
      daEventCenter.triggerNotice({
        daId: '2-27-11',
        extraData: {
          store_code: searchStoreCard.store_code,
          store_banner: target.className.includes('banner-img') ? 1 : 0,
          is_multiple_card: this.storeCardNum > 1
        },
      })
      const { src_module = '', src_identifier, src_tab_page_id = '' } = parseQueryString(location.search)
      const srcData = {
        src_module,
        src_identifier,
        src_tab_page_id,
        page_from: 'PageSearchResult',
        rule_poskey: 'SearchshopitemList'
      }
      const { langPath = '' } = gbCommonInfo || {}
      const url = `${langPath}/store/home?store_code=${
        searchStoreCard.store_code
      }&${stringifyQueryString({ queryObj: srcData })}`
      this.$router.push(url)
    },
    // 跳转到店铺主页并置顶商品
    async handleStoreDetailClick(product = {}, searchStoreCard) {
      const { store_code } = searchStoreCard
      const { goods_id, goods_sn, productRelationID, salePrice, retailPrice, mall_code, cat_id } = product
      const goodsId = goods_id
      const skuId = goods_sn
      const spuId = productRelationID
      const page = 1
      const index = 0
      const operation = 1
      const recMark = ''
      const price = salePrice?.usdAmount
      const originPrice = retailPrice?.usdAmount
      const  goods_to_list = `${goodsId}\`${skuId}\`${spuId}\`${+index + 1}\`${page}\`${operation}\`${recMark}\`\`pri_${price}|estimated_pri_${originPrice}\`\`\`mall_${mall_code}` // goods_list: goods_id`sku_id`spu_id`坑位`页码`运营位置`流量标识rec_mark
      daEventCenter.triggerNotice({
        daId: '2-27-11',
        extraData: {
          goods_to_list,
          store_code: store_code,
          is_multiple_card: this.storeCardNum > 1
        },
      })
      
      const { langPath = '' } = gbCommonInfo || {}
      const { src_module = '', src_identifier, src_tab_page_id = '' } = parseQueryString(location.search)
      const srcData = {
        store_code: store_code,
        adp: goods_id,
        page_from: 'PageSearchResult',
        src_tab_page_id,
        src_module,
        src_identifier,
        main_goods_id: goods_id,
        main_cate_id: cat_id,
        rule_poskey: 'SearchshopitemList',
        ...(goods_id ? { isStoreTabSticky: 1, tabId: 'items' } : null)
      }
      const url = `${langPath}/store/home?${stringifyQueryString({ queryObj: srcData })}`
      this.$router.push(url)
    }
  },
})
</script>

<style lang="less" scoped>
html[mir='rtl'] .header__store_brand .header__label_icon{ 
  transform: rotateY(180deg);
}
// 设计稿用的 375px
@unit: 375/100vw;
.banner-wrap {
  padding: 6 / @unit 8 / @unit;
  background-color: var(---gray_weak2, #F6F6F6);
  &.fashion-card {
    position: relative;
    z-index: 1;
    background-color: transparent;
    .search-shop-banner {
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.60);
      backdrop-filter: blur(4px);
      border: 1px solid rgba(151, 128, 199, 0.10);
    }
    .store-data__divider-line {
      background-color: #959595;
    }
  }
}
.search-shop-banner {
  padding: 6 / @unit;
  border-radius: 8 / @unit;
  background: #FFF;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__content {
    margin-top: 8 / @unit;
    display: flex;
    align-items: center;
  }
  &__banner {
      margin-top: 8 / @unit;
      height: 100 / @unit;
      /* stylelint-disable-next-line selector-nested-pattern */
      &-img {
        height: 100%;
        width: 100%;
        border-radius: 4 / @unit;
        background-repeat: no-repeat;
        background-size: cover;
      }
  }
}
.header {
  &__left {
    display: flex;
    align-items: center;
    height: 40 / @unit;
  }
  &__right {
    border-radius: 4 / @unit;
  }
  &__logo {
    width: 40 / @unit;
    height: 40 / @unit;
    border-radius: 4 / @unit;
    .border-dpr(border, 1 / @unit, #E5E5E5);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    background-color: #FFFFFF;
    > img {
      max-height: 100%;
      height: auto;
    }
  }
  &__content {
    height: 40 / @unit;
    margin-left: 8 / @unit;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__title {
    color: #000;
    font-size: 14 / @unit;
    font-weight: 700;
    line-height: 17 / @unit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 180 / @unit;
  }
  &__labels-container {
    display: flex;
    align-items: center;
    margin-top: 4 / @unit;
    flex-wrap: wrap;
    height: 16 / @unit;
    overflow: hidden;
    margin-right: 8 / @unit;
  }
  &__labels {
    display: flex;
    align-items: center;
    margin-right: 4 / @unit;
  }
  &__label {
    height: 16 / @unit;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-right: 2 / @unit;
  }
  &__store_brand {
    display: flex;
    padding: 0 5 / @unit 0 2 /@unit;
    align-items: center;
    border-radius: 2 / @unit;
    color: #265999;
    font-size: 13  / @unit;
    font-style: italic;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 2 / @unit;
    background: #E0F1FF;
    .header__label_icon  img {
      transform: translateY(-0.5 / @unit);
      width: 12 / @unit;
      height: 12 / @unit;
    }
    .header__label_txt {
      font-weight: 700;
      font-family: 'SF UI Text', 'PingFang TC', 'sans-serif';
    }
  }
  &__store_best {
    display: flex;
    padding: 0 4 / @unit 0 2 /@unit;
    align-items: center;
    border-radius: 2 / @unit;
    background: #FFF8EB;
    color: #A86104;
  }
  &__label-image {
    height: 16 / @unit;
    display: flex;
    img {
      height: 100%;
    }
  }
  &__label-fallback {
    height: 16 / @unit;
    background-color: #9462ff;
    color: #fff;
    position: relative;
    border-radius: 2 / @unit;
    padding: 0 4 / @unit;
    height: 16 / @unit;
  }
  &__label_icon {
    width: 12 / @unit;
    margin-right: 2 / @unit;
  }
  &__label_txt {
    height: 16 / @unit;
    font-size: 12 / @unit;
    line-height: 16 / @unit;
  }
  &__store_normal {
    display: flex;
    height: 100%;
    align-items: center;
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5 / @unit 8 / @unit;
    background: var(---sui_color_gray_dark1, #222);
    color: var(---sui_color_white, #FFF);
    font-size: 12 / @unit;
  }
}
.store-data {
  &__line {
    display: flex;
    align-items: center;
    padding-right: 8 / @unit;
    line-height: normal;
  }
  &__key {
    font-size: 11 / @unit;
    color: #000;
  }
  &__mark {
    color: #000;
    font-size: 11 / @unit;
    margin-right: 4 / @unit;
  }
  &__value {
    color: #000;
    font-size: 12 / @unit;
    font-weight: 500;
  }
  &__hot-labels{
    display: flex;
    padding-left: 8 / @unit;
    position: relative;
  }
  &__divider-line {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1 / @unit;
    height: 10 / @unit;
    display: inline-block;
    background: #E5E5E5;
  }
}
.goods-list {
  margin-top: 8 / @unit;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6 / @unit;
  &__item {
    position: relative;
  }
  &__img {
    width: 80 / @unit;
    height: 80 / @unit;
    border-radius: 4 / @unit;
    box-shadow: 0 0 1 / @unit 0 rgba(0, 0, 0, 0.2);
    object-fit: cover;
    object-position: center;
  }
  &__price {
    padding: 1 / @unit 3 / @unit;
    position: absolute;
    bottom: 4 / @unit;
    right: 4 / @unit;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2 / @unit;
    background: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(1 / @unit);
    box-shadow: 0 0 2 / @unit 0 rgba(0, 0, 0, 0.12);
  }
  :deep(.product-card__price-simple .price-content){
    padding-right: 0;
  }
}

// 多个卡片的样式重写
.multiple-card {
  display: flex;
  overflow-x: scroll;
  gap: 8 / @unit;
  padding: 8 / @unit;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  .header {
    &__logo {
      width: 40px;
      height: 40px;
    }
    &__content {
      // margin-left: 6 / @unit;
      // padding: 4 / @unit 0;
      margin-left: 6px;
      padding: 4px 0;
    }
    &__title {
      // width: 102 / @unit;
      // font-size: 12 / @unit;
      width: 102px;
      font-size: 12px;
      line-height: normal;
    }
    &__labels-container {
      // width: 102 / @unit;
      width: 102px;
      margin-right: unset;
      height: 14px;
      margin-top: 4px;
    }
    &__label-image {
      // height: 14 / @unit;
      height: 14px;
    }
    &__left {
      height: 40px;
    }
    &__store_brand {
      padding: 0 5px 0 2px;
      font-size: 12px;
      height: 14px;
      .header__label_txt {
        height: 14px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  .search-shop-banner {
    // padding: 6 / @unit;
    padding: 6px;
    //outline: 1px solid #fff;
    box-shadow: 0 0 0 1px #fff;
    &__content {
      // margin-top: 6 / @unit;
      // max-width: 150 / @unit;
      margin-top: 6px;
      max-width: 150px;
      overflow: hidden;
      // height: 14 / @unit;
      height: 14px;
    }
    &_special {
      background: linear-gradient(180deg, #EDE7FE 0%, #FFF 50%);
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url('//img.ltwebstatic.com/v4/p/ccc/2025/03/24/98/1742782441c2c2392c1a4711cf4577bf3e42a5ba67.webp') /* rtl: url('//img.ltwebstatic.com/v4/p/ccc/2025/03/24/65/1742782441a7c505e6a59d205e5c5ce8e28a2992ce.webp') */;
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;
        border-radius: 8 / @unit;
      }
    }
  }
  // 处理评分&&关注数 部分的溢出样式
  .store-data {
    // max-width: 150 / @unit;
    max-width: 150px;
    flex-wrap: wrap;
    &__line {
      line-height: 14px;
      padding-right: 8px;
      // overflow: hidden;
      &:is(:last-child) {
        padding-right: 0;
      }
    }
    &__key {
      // max-width: 150 / @unit;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }
    &__mark {
      flex-shrink: 0;
    }
    &__value {
      flex: 1;
      font-size: 12px;
      // max-width: 25 / @unit;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }
  }
  .goods-list {
    grid-template-columns: repeat(2, 1fr);
    // gap: 6 / @unit;
    gap: 6px;
    // margin-top: 6 / @unit;
    margin-top: 6px;
    &__img {
      // width: 72 / @unit;
      // height: 72 / @unit;
      width: 72px;
      height: 72px;
    }
    &__price {
      // padding: 1 / @unit, 2 / @unit, 2 / @unit, 2 / @unit; 
      padding: 1px, 2px, 2px, 2px; 
      // font-size: 10 / @unit;
      // max-width: 64 / @unit; // 限制最大宽度，多语言站点溢出问题
      font-size: 10px;
      max-width: 64px; // 限制最大宽度，多语言站点溢出问题
    }
  }
}
// 两个卡片时（部分样式继承上面的multiple-card，部分重写）
.two-card {
  padding: 6 / @unit;
  gap: 6 / @unit;
  .header {
    &__title {
      width: 120 / @unit;
    }
    &__labels-container {
      width: 120 / @unit;
    }
  }
  .search-shop-banner {
    &__content {
      max-width: 167 / @unit;
      overflow: hidden;
    }
  }
  // 处理评分&&关注数 部分的溢出样式
  .store-data {
    max-width: 167 / @unit;
    &__line {
      overflow: hidden;
      &:is(:last-child) {
        padding-right: 0;
      }
    }
    &__key {
      max-width: 167 / @unit;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__mark {
      flex-shrink: 0;
    }
    &__value {
      flex: 1;
      max-width: 167 / @unit;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .goods-list {
    &__img {
      width: 80 / @unit;
      height: 80 / @unit;
    }
    &__price {
      max-width: calc(100% - 8); // 限制最大宽度，多语言站点溢出问题
    }
  }
}

</style>
