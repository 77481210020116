<template>
  <div 
    class="search-trend-card-background"
  >
    <img
      src="//img.ltwebstatic.com/images3_ccc/2024/05/21/45/17162772482320df6564cdf819da0dcf50402ffdba.png"
      alt="bg-img"
    />
  </div>
</template>

<script setup>

</script>

<style lang="less">
.search-trend-card-background {
  width: 100%;
  height: auto;
  background-color: #fff;
  img {
    width: 100%;
    height: auto;
    position: relative;
    top: -2.1667rem;
  }
}
</style>
