<template>
  <div
    v-if="COMPONENT_MAP[type]"
    class="product-list-top-background"
    :style="style"
  >
    <component
      :is="COMPONENT_MAP[type]"
      :extend-data="props.extendData"
    />
  </div>
</template>

<script setup>
import {
  defineProps,
  onMounted,
  onUnmounted,
  onActivated,
  onDeactivated,
  getCurrentInstance,
  reactive,
  ref
} from 'vue'
import TabPicNav from './components/TabPicNav.vue'
import SearchTrendCard from './components/SearchTrendCard.vue'
import SearchStoreCard from './components/SearchStoreCard.vue'
const props = defineProps({
  type: {
    type: String,
    default: ''
  },
  changeHeaderBg: {
    type: Boolean,
    default: false
  },
  scrollTransparent: {
    type: Boolean,
    default: false
  },
  extendData: {
    type: Object,
    default: () => ({})
  }
})

const style = reactive({
  opacity: 1
})

const isActivated = ref(false)

const vm = getCurrentInstance()

const useStore = () => {
  if (!vm) return console.warn('useStore must be used after setup')
  return vm?.proxy?.$store
}
const { commit } = useStore()

const COMPONENT_MAP = {
  TabPicNav,
  SearchTrendCard,
  SearchStoreCard
}

const setOpacity = (positionY) => {
  if (positionY > 44) {
    style.opacity = 0
  } else {
    style.opacity = 1
  }
}

const setHeadBg = (positionY, isScroll) => {
  // 打开dialog（快加车） 会将body设置为fixed 届时scrollY会失效
  const bodyElement = document.body
  const isFixed = bodyElement.style.position === 'fixed'
  // 控制顶部背景色
  if (positionY > 10 || isFixed) {
    if (!vm.proxy.$store.state.commonHeaderBg?.showBlock && isScroll) {
      return
    }
    commit('changeHomeImmersiveInfo', {
      commonHeaderBg: {
        showBlock: false,
        searchInputBgColor: ''
      }
    })
  } else {
    if (vm.proxy.$store.state.commonHeaderBg?.showBlock && isScroll) {
      return
    }
    commit('changeHomeImmersiveInfo', {
      commonHeaderBg: {
        showBlock: true,
        topTextColor: '#222',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        searchInputBgColor: 'rgba(255, 255, 255, 0.8)',
      }
    })
  }
}
const handleScroll = ({
  isScroll = true
}) => {
  if (!isActivated.value) return
  const positionY = window.scrollY
  props.changeHeaderBg && setHeadBg(positionY, isScroll)
  props.scrollTransparent && setOpacity(positionY)
}
onActivated(() => {
  isActivated.value = true
  handleScroll({
    isScroll: false
  })

})
onDeactivated(() => {
  isActivated.value = false
  commit('changeHomeImmersiveInfo', {
    commonHeaderBg: {
      showBlock: false,
      backgroundColor: '#FFFFFF',
      searchInputBgColor: ''
    }
  })
})
onMounted(() => {
  isActivated.value = true
  handleScroll({
    isScroll: false
  })
  window.addEventListener('scroll', handleScroll, true)
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll, true)
  commit('changeHomeImmersiveInfo', {
    commonHeaderBg: {
      showBlock: false,
      backgroundColor: '#FFFFFF',
      searchInputBgColor: ''
    }
  })
})
</script>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TopBackground'
})
</script>

<style lang="less">
.product-list-top-background {
  position: absolute;
  top: -1.37rem;
  width: 100%;
  transition: opacity 0.3s;
}
</style>
