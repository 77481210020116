import { addHistoryData } from './utils/searchHistoryLocalStorage.js'
import { htmlDecode, getQueryString } from '@shein/common-function'
import { getStoreCodeFromUrl } from 'public/src/pages/store_pages/js/utils.js'

import { TAB_MAP_LIST } from './searchConst'

function syncHisData({ keywords, afterTreatmentKeywords, empty, pageType = 'all', localCacheWordKey = '', word_id }) {

  gbCommonInfo.keywords = keywords

  // 空值不存入缓存
  if (!empty) {
    const _afterTreatmentKeywords = afterTreatmentKeywords ?? keywords
    keywords && addHistoryData({
      keywords: _afterTreatmentKeywords, 
      pageType, 
      key: localCacheWordKey, 
      word_id
    })

    appEventCenter.emit('syncSearchState')
  } else {
    appEventCenter.emit('syncSearchState', { empty })
  }
}

function initSearchPageFirstLoadEvent() {
  appEventCenter.on('searchPageFirstLoad', ({ keywords: searchWords, origin_total, pageType = 'all', localCacheWordKey, word_id = 0 }) => {
    const keywords = decodeURIComponent(searchWords)
    if (origin_total ) {
      const search_id = getQueryString({ key: 'search_id' }) || undefined
      const search_cat = getQueryString({ key: 'search_cat' }) || undefined

      let afterTreatmentKeywords = search_id ? { keywords, guideObj: { search_id, search_cat } } : keywords

      syncHisData({ keywords, afterTreatmentKeywords, pageType, localCacheWordKey, word_id })
    } else {
      syncHisData({ keywords, empty: true, pageType, localCacheWordKey, word_id })
    }
  })
}

function initSearchRedirectEvent() {
  // search redir spa
  appEventCenter.on('searchRedirect', ({ searchWords, pageType, localCacheWordKey, word_id }) => {
    if (!searchWords) return

    const searchRedirectWords = htmlDecode({ text: searchWords })
    searchRedirectWords && syncHisData({ keywords: searchRedirectWords, pageType, localCacheWordKey, word_id })
  })
}

function cacheSearchRedirectWords(){
  // search redir landing
  const searchRedirectWords = getQueryString({ key: 'search_redir' }) && getQueryString({ key: 'search_words' })
  const pageType = getStoreCodeFromUrl() ? 'store' : 'all'
  searchRedirectWords && syncHisData({ 
    keywords: searchRedirectWords, pageType, 
    word_id: getQueryString({ key: 'word_id' }) || 0 })
}

function keywordPageUrlHandler(keyword) {

  // 处理ccc没配置路径的词类型
  if(Object.keys(TAB_MAP_LIST).includes(`${keyword?.pageType}`)) {
    keyword.pageUrl = TAB_MAP_LIST[`${keyword.pageType}`].url
  }
  const handler = ({ params, isClick, pageType, query }) => {
    if (keyword?.pageType == 2 || keyword?.pageType == 5) {
      // mallCode
      const mallCode = keyword.pageId?.split('/')[1] || ''
      if (mallCode) {
        params.mallCode = mallCode
      }
    }
    // 新增跳转类型Category 参数处理
    if(keyword?.pageType === 26) {
      const channelType = keyword.categoryChannel //category频道
      const cateId = keyword.cateId //品类ID
      params.channelId = channelType
      params.oneCate = cateId
    }
    
    isClick && appEventCenter.emit('searchRedirect', { 
      searchWords: keyword.word, 
      pageType, 
      storeCode: query.store_code, 
      word_id: keyword.word_id || 0,
    })
  }
  return handler
}

export {
  keywordPageUrlHandler,
  initSearchPageFirstLoadEvent,
  initSearchRedirectEvent,
  cacheSearchRedirectWords,
}
