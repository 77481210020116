<template>
  <div 
    class="search-store-card-background"
  >
    <img
      :src="props.extendData.img"
      alt="bg-img"
    />
  </div>
</template>
  
<script setup>
import {
  defineProps,
} from 'vue'
const props = defineProps({
  extendData: {
    type: Object,
    default: () => ({})
  }
})
</script>
  
  <style lang="less">
  .search-store-card-background {
    width: 100%;
    height: auto;
    box-sizing: content-box;
    padding-bottom: 16px;
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
  </style>
  
