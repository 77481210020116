
// 背景： cate和attrs数据量大，且不是首屏不需要全部加载
// 延后数据，切一部分数据，只将筛入一部分数据，不影响页面展示
// 收益：  vuex响应式加载耗时减少， 组件耗时减少，页面渲染速度提升
// 但会影响回显，这里做的只是首屏，无出现回显的情况
// 1.将属性和分类数据都放入一个数据进入store，此时页面渲染也只会渲染一部分数据
// 2.将标签云也只塞入6个数据store里面，先进行上屏。
const JOIN_LIST = [
  'page_real_class',
  'page_select_class',
  'page_search',
]

/**
 * @description: 递归获取第一屏数据, 取每个child的前几个
 * */ 
function getFirstChildren(data = [], initLevel = 1) {
  if (!data?.length) return []
  const dataLevel = data.slice(0, initLevel) // 第一次取前面几个，用于首屏展示
  return dataLevel.map(item => {
    if (!item) return
    return {
      ...item,
      children: getFirstChildren(item.children, initLevel)
    }
  }).filter(Boolean)
}

/**
 * @description: 优化首屏商品数据处理，切割首屏数据和后续数据
 * @param {Object} newData - 新数据
 * @param {Function} callBack - 回调函数  回调参数为原始数据
*/
function optimizeGoodsData(newData, callBack) {
  if (newData.cat_info?.requestType !== 'firstload' || typeof window === 'undefined' || !newData) {
    return
  }
  const { apolloConfig, cat_info } = newData
  const { type, pageName } = cat_info || {}
  if (apolloConfig?.[type] == 'off' || !JOIN_LIST.includes(pageName)) return

  const originalData = {
    filterCates: newData?.filterCates || [],
  }
  newData.filterCates = getFirstChildren(originalData.filterCates, 2)
  appEventCenter.once('goodsLoadedOnPageList', () => { // 等待第一屏商品处理完成后触发的事件
    callBack(originalData)
  })
}

export { optimizeGoodsData }
