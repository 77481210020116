import schttp from 'public/src/services/schttp'

// buyBoxPrice: {price: "$3.57"}

// web中间层请求
// const webRquset = async ({ products }) => {

//   const { list } = await schttp({
//     method: 'POST',
//     url: '/api/productList/buyBoxPrice/get',
//     data: { products }
//   })
  
//   return list || []
// }

// java中间层请求
const bffRquset = async ({ products, sheinClubInfo }) => {
  const res = await schttp({
    useBffApi: true,
    method: 'POST',
    url: '/recommend/buybox/buybox_entrance',
    data: { 
      goodsInfos: products,
      isPaidMember: sheinClubInfo?.isPaid ? 1 : 0,
    }
  })

  const buyBoxEntranceList = res?.info?.buyBoxEntranceList || []
  if (buyBoxEntranceList.length > 0) {
    const product = buyBoxEntranceList.map((item) => {
      return {
        goods_id: Number(item.goodsId),
        buyBoxPrice: {
          price: item.buyBox?.lowestPrice
        }
      }
    }) 
    return product || []
  }
  return []
}

export default {
  methods: {
    async getBuyBoxPrices (goods, sheinClubInfo) {
      const products = goods
        .filter(item => item.buyBoxCount?.count)
        .map(item => {
          return {
            // java中间层用
            goodsId: item.goods_id,
            goodsSn: item.goods_sn,
            mallCode: item.mall_code
          }
        })
      
      if (!products.length) return

      const resProduct = await bffRquset({ products, sheinClubInfo })

      resProduct.forEach(item => {
        const curGoods = this.goods.find(obj => obj.goods_id === item.goods_id)
        if (!curGoods) return
        curGoods.buyBoxPrice = item.buyBoxPrice
      })
    }
  },
}
