/**
 * 用于修复 Vue3 下
 * 服务端渲染后， `infinite-scroll-distance` 无法更新客户端新的响应值问题
 */

export default {
  mounted(el, binding) {
    el.setAttribute('infinite-scroll-distance', binding.value)
  },
}
